import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {
  AssetLinkBlock,
  BLOCKS,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  OrderedList,
  Paragraph,
  Quote,
  Table as TableBlock,
  Text,
} from '@contentful/rich-text-types';
import { useMemo } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import Imgix from 'react-imgix';
import ReviewsModule from '../about/ReviewsModule';
import SkyhookExperience from '../about/SkyhookExperience';
import { getImageUrl } from '../media/utils/imgix';
import LoyaltyRewards from '../SkyhookClub/LoyaltyRewards';
import PartnerDiscounts from '../SkyhookClub/PartnerDiscounts';

type ContentfulContentProps = {
  document: Record<string, any>;
  contentField?: string;
};

export default function ContentfulContent({
  document,
  contentField = 'content',
}: ContentfulContentProps) {
  const dtrOptions = useMemo(
    () => ({
      renderNode: {
        // Text
        [BLOCKS.PARAGRAPH]: (node: Paragraph) => {
          return (
            <Container>
              <Row>
                <Col xs={12} sm={8} md={7} className="mx-auto">
                  {documentToReactComponents(node as any)}
                </Col>
              </Row>
            </Container>
          );
        },
        [BLOCKS.HEADING_1]: (node: Heading1) => {
          return (
            <Container>
              <Row>
                <Col xs={12} sm={8} md={7} className="mx-auto mb-3">
                  {node.content.map((content: Text, idx) => (
                    <h1 key={idx}>{content.value}</h1>
                  ))}
                </Col>
              </Row>
            </Container>
          );
        },
        [BLOCKS.HEADING_2]: (node: Heading2) => {
          const textContent = documentToPlainTextString(node);

          const isOurStory = textContent.toLowerCase() === 'our story';

          const includeRewards =
            textContent.toLowerCase() ===
            'Rewards at Your Fingertips'.toLowerCase();

          const includePartnerDiscounts =
            textContent.toLowerCase() ===
            'Skyhook Credits: What You Need to Know'.toLowerCase();

          return (
            <>
              {isOurStory && (
                <>
                  <ReviewsModule />
                  <SkyhookExperience />
                </>
              )}

              {includeRewards && <LoyaltyRewards />}
              {includePartnerDiscounts && <PartnerDiscounts />}
              <Container>
                <Row className={isOurStory ? 'mt-3' : ''}>
                  <Col xs={12} sm={8} md={7} className="mx-auto mt-3 mb-1">
                    {node.content.map((content: Text, idx) => (
                      <h2 key={idx}>{content.value}</h2>
                    ))}
                  </Col>
                </Row>
              </Container>
            </>
          );
        },
        [BLOCKS.HEADING_3]: (node: Heading3) => {
          return (
            <Container>
              <Row>
                <Col xs={12} sm={8} md={7} className="mx-auto">
                  {node.content.map((content: Text, idx) => (
                    <h3 key={idx}>{content.value}</h3>
                  ))}
                </Col>
              </Row>
            </Container>
          );
        },
        [BLOCKS.HEADING_4]: (node: Heading4) => {
          return (
            <Container>
              <Row>
                <Col xs={12} sm={8} md={7} className="mx-auto">
                  {node.content.map((content: Text, idx) => (
                    <h4 key={idx}>{content.value}</h4>
                  ))}
                </Col>
              </Row>
            </Container>
          );
        },
        [BLOCKS.HEADING_5]: (node: Heading5) => {
          return (
            <Container>
              <Row>
                <Col xs={12} sm={8} md={7} className="mx-auto">
                  {node.content.map((content: Text, idx) => (
                    <h5 key={idx}>{content.value}</h5>
                  ))}
                </Col>
              </Row>
            </Container>
          );
        },
        [BLOCKS.HEADING_6]: (node: Heading6) => {
          return (
            <Container>
              <Row>
                <Col xs={12} sm={8} md={7} className="mx-auto">
                  {node.content.map((content: Text, idx) => (
                    <h6 key={idx}>{content.value}</h6>
                  ))}
                </Col>
              </Row>
            </Container>
          );
        },
        [BLOCKS.QUOTE]: (node: Quote) => {
          return (
            <Container>
              <Row>
                <Col xs={12} sm={8} md={7} className="mx-auto">
                  {node.content.map((paragraph: Paragraph, idx) => (
                    <blockquote key={idx} className="blockquote">
                      {documentToReactComponents(paragraph as any)}
                    </blockquote>
                  ))}
                </Col>
              </Row>
            </Container>
          );
        },
        [BLOCKS.OL_LIST]: (node: OrderedList) => {
          return (
            <Container>
              <Row>
                <Col xs={12} sm={8} md={7} className="mx-auto">
                  {documentToReactComponents(node as any)}
                </Col>
              </Row>
            </Container>
          );
        },
        [BLOCKS.UL_LIST]: (node: OrderedList) => {
          return (
            <Container>
              <Row>
                <Col xs={12} sm={8} md={7} className="mx-auto">
                  {documentToReactComponents(node as any)}
                </Col>
              </Row>
            </Container>
          );
        },
        // Tables
        [BLOCKS.TABLE]: (node: TableBlock) => {
          const rows = node.content;
          const headerRows = rows.filter(
            (row) => row.content[0]?.nodeType === 'table-header-cell',
          );
          const otherRows = rows.filter(
            (row) => row.content[0]?.nodeType !== 'table-header-cell',
          );

          return (
            <Container>
              <Row>
                <Col className="mx-auto mt-3 mb-4">
                  <Table striped responsive="xs" hover size="sm">
                    <thead>
                      {headerRows.map((row) =>
                        documentToReactComponents(row as any),
                      )}
                    </thead>
                    <tbody>
                      {otherRows.map((row) =>
                        documentToReactComponents(row as any),
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Container>
          );
        },
        [BLOCKS.EMBEDDED_ASSET]: (node: AssetLinkBlock) => {
          // Get the image details
          const { id } = node.data.target.sys;
          const image = document[contentField].links.assets.block.find(
            (i) => i.sys.id === id,
          );

          // Return if it doesn't have a URL (this can happen in draft mode)
          if (!image.url) return null;

          // Return the image in a full-width row
          return (
            <Container>
              <Row className="my-3">
                <Col className="text-center">
                  <figure>
                    <Imgix
                      src={`${getImageUrl(
                        image.url,
                        process.env.NEXT_PUBLIC_CONTENTFUL_IMGIX_URL,
                      )}`}
                      imgixParams={{
                        auto: 'compress,format,enhance,redeye',
                      }}
                      sizes="(min-width: 1400px) 1296px, (min-width: 1200px) 1116px, (min-width: 992px) 936px, (min-width: 768px) 696px, (min-width: 576px) 516px, 98vw"
                      className="figure-img img-fluid"
                      htmlAttributes={{
                        alt: image.title || image.description || '',
                        style: {
                          maxHeight: '90vh',
                        },
                      }}
                    />
                    {image.description && (
                      <figcaption className="figure-caption text-center">
                        {image.description}
                      </figcaption>
                    )}
                  </figure>
                </Col>
              </Row>
            </Container>
          );
        },
      },
    }),
    [contentField, document],
  );

  return (
    <>{documentToReactComponents(document[contentField].json, dtrOptions)}</>
  );
}
