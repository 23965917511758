import { Col, Container } from 'react-bootstrap';
import Imgix from 'react-imgix';
import { getImageUrl } from './media/utils/imgix';

type Props = {
  title: string;
  image: string;
  cdn?: string;
};

export default function HeaderSection({
  title,
  image,
  cdn = process.env.NEXT_PUBLIC_CONTENTFUL_IMGIX_URL,
}: Props) {
  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <div
        className="d-block d-sm-none"
        style={{
          display: 'block',
          aspectRatio: '1/1',
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <Imgix
          imgixParams={{
            auto: 'compress,format,enhance,redeye',
            crop: 'faces,center',
            fit: 'crop',
            ar: '1:1',
            w: '576px',
          }}
          src={`${getImageUrl(image, cdn)}`}
          sizes="100vw"
          className="w-100 h-100"
        />
      </div>
      <div
        className="d-none d-sm-block d-md-none"
        style={{
          display: 'block',
          overflow: 'hidden',
          width: '100%',
          aspectRatio: '5/3',
        }}
      >
        <Imgix
          imgixParams={{
            auto: 'compress,format,enhance,redeye',
            crop: 'faces,center',
            fit: 'crop',
            ar: '5:3',
            w: '768',
          }}
          src={`${getImageUrl(image, cdn)}`}
          sizes="100vw"
          className="w-100 h-100"
        />
      </div>
      <div
        className="d-none d-md-block d-lg-none"
        style={{
          display: 'block',
          overflow: 'hidden',
          width: '100%',
          aspectRatio: '5/3',
        }}
      >
        <Imgix
          imgixParams={{
            auto: 'compress,format,enhance,redeye',
            crop: 'faces,center',
            fit: 'crop',
            ar: '5:3',
            w: '992px',
          }}
          src={`${getImageUrl(image, cdn)}`}
          sizes="100vw"
          className="w-100 h-100"
        />
      </div>
      <div
        className="d-none d-lg-block d-xl-none"
        style={{
          display: 'block',
          overflow: 'hidden',
          width: '100%',
          aspectRatio: '5/2',
        }}
      >
        <Imgix
          imgixParams={{
            auto: 'compress,format,enhance,redeye',
            crop: 'faces,center',
            fit: 'crop',
            ar: '5:2',
            w: '1200px',
          }}
          src={`${getImageUrl(image, cdn)}`}
          sizes="100vw"
          className="w-100 h-100"
        />
      </div>
      <div
        className="d-none d-xl-block"
        style={{
          display: 'block',
          overflow: 'hidden',
          width: '100%',
          aspectRatio: '5/1.7',
        }}
      >
        <Imgix
          imgixParams={{
            auto: 'compress,format,enhance,redeye',
            crop: 'faces,center',
            fit: 'crop',
            ar: '5:1.7',
            w: '1400px',
          }}
          src={`${getImageUrl(image, cdn)}`}
          sizes="100vw"
          className="w-100 h-100"
        />
      </div>

      <Container
        fluid
        style={{
          position: 'absolute',
          bottom: 0,
          top: 0,
          left: 0,
          right: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          background: 'rgba(0, 0, 0, 0.25)',
          textShadow: '2px 2px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Container>
          <Col xs={12} lg={10} xl={8} className="text-center mx-auto">
            <h1
              className="text-white text-center py-2 display-4 fw-bold px-3 w-auto mx-auto"
              style={{
                textShadow: '2px 2px rgba(0, 0, 0, 0.2)',
              }}
            >
              {title}
            </h1>
          </Col>
        </Container>
      </Container>
    </div>
  );
}
